<template>
    <div class="container" v-bind:style="'height:'+  (screenHeight-70) +'px;'">
        <div class="row justify-content-center align-items-center">
            <div class="card col-md-4 mt-5">
                    <div class="card-header bg-transparent pt-3 p-0 ps-2">
                        <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Update password</p>
                    </div>
                    <div class="card-body">
                        <form class="container" id="frmPass" v-on:submit.prevent="saveData" autocomplete="off">
                            <div class="mb-3">
                                <label class="form-label h9 fw-bold">Username :</label>
                                    <input v-model="rec.username" class="form-control" type="mail" placeholder="Enter Username" readonly>
                            </div>
                            <div class="mb-3">
                                <label class="form-label h9 fw-bold">Password lama :</label>
                                <div class="input-group">
                                    <input v-model="rec.passold" class="form-control" :type="lihatold?'text':'password'" placeholder="Enter Password lama">
                                    <span v-on:click="lihatold=!lihatold" type="button" class="input-group-text" style=""><i class="fa" :class="lihatold?'fa-eye':'fa-eye-slash'"></i></span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label h9 fw-bold">Password baru :</label>
                                <div class="input-group">
                                    <input v-model="rec.passnew" class="form-control" :type="lihatnew?'text':'password'" placeholder="Enter Password baru">
                                    <span v-on:click="lihatnew=!lihatnew" type="button" class="input-group-text" style=""><i class="fa" :class="lihatnew?'fa-eye':'fa-eye-slash'"></i></span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer">
                        <div class="w3-right">
                            <button form="frmPass" class="btn btn-success" type="submit"><i class="fa fa-save"></i> Save</button>
                        <button type="button" class="btn btn-danger ms-2" v-on:click="toHome()"><i class="fa fa-close"></i> Close</button>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Profile',
    components: {
    },
    data() {
        return {
            screenHeight : 0,
            lihatold : false,
            lihatnew : false,
            rec: {
                username : localStorage.username,
                passold : '',
                passnew : ''
            }
        }
    },
    methods : {
        saveData(event){
            var self = this;
            self.rec['pfunction'] = 'updatepass';
            axios.post("admin/ApiAdmin.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.rec = {
                        username : localStorage.username,
                        passold : '',
                        passnew : ''
                    }
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        toHome(){
            this.$router.push("/home");
        }
    },
    filters: {
    },
    watch: {
    },
    computed :{
    },
    mounted(){
        this.screenHeight = window.innerHeight;
    }
};
</script>